<template>
  <b-container fluid>
    <b-row v-if="totalEntityCount != 1">
      <b-col md="12">
        <span class="badge mr-1 badge-primary badge-pill">{{ $t("Line") }} {{ tssIndex+1 }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-row>
          <b-form-group class="col-md-12"
                        label-for="service"
                        :label="$t('Service')">
            <v-select transition="" v-model="serviceKey"
                      :reduce="s => `${s.id}`" label="name"
                      :filterBy="$root.filterOptions"
                      :searchable="true"
                      :options="serviceList">
              <template v-slot:no-options>
                {{$t('NoMatchingOptionsMessage')}}
              </template>
            </v-select>
          </b-form-group>
        </b-row>
      </b-col>
      <b-col md="2" class="d-flex justify-content-center align-items-center" v-if="$parent.rowCount != 1">
        <button type="button" class="btn btn-danger" @click="removeRow(tss)">
          <i class="fa fa-times" />
        </button>
      </b-col>
    </b-row>
    <br />
  </b-container>
</template>

<script>
  import entityService from '../../services/entity';

  export default {
    props: ["tssIndex", "initialTss", "staffId", "staffBranchCalendarId"],
    data() {
      return {
        serviceList: [],
        serviceKey: ''
      }
    },
    methods: {
      getServiceList(staffId) {
        entityService.getServiceList(null, staffId, this.staffBranchCalendarId).then(response => {
          this.serviceList = response;
        });
      },
      removeRow() {
        this.$parent.removeRow(this.initialTss);
      },
      calculateRowTotal: function () {
        let $this = this;
        let originalUsePrice = (this.tss.useCreditPrice == true ? this.tss.entity.basePriceWithCard : this.tss.entity.basePrice);
        let quantitiedPrice = originalUsePrice * $this.tss.quantity;

        if ($this.tss.userInputDiscount && $this.tss.userInputDiscount.toString().startsWith('%')) {

          let inputDiscount = $this.tss.userInputDiscount.toString().split('%')[1];
          if (!isNaN(inputDiscount)) {
            quantitiedPrice = quantitiedPrice - ((quantitiedPrice * inputDiscount) / 100);
            $this.tss.rowTotal = quantitiedPrice.toFixed(2);
          }
        }
        else if ($this.tss.userInputDiscount && !isNaN($this.tss.userInputDiscount)) {
          $this.tss.rowTotal = (quantitiedPrice - $this.tss.userInputDiscount).toFixed(2);
        }
        else {
          $this.tss.rowTotal = quantitiedPrice.toFixed(2);
        }

      }
    },
    computed: {
      totalEntityCount: function () {
        return this.$parent.totalEntityCount;
      },
      tss: function () {
        return this.initialTss;
      },
      customerDiscount: function () {
        return this.$parent.customerDiscount;
      }
    },
    watch: {
      staffId: {
        handler: function (id) {
          if (id && this.tss.entityType != 'product') {
            if (this.tss.entityId && this.$parent.serviceRows.length > 1) {
              this.serviceKey = '';
              this.$parent.serviceRows = [];
              this.$parent.addEntityRow();
            }
          }
          this.getServiceList(id);
        },
        immediate: true
      },
      'tss.quantity': function (val) {
        if (val) {
          this.tss.quantity = val.toString().replace(',', '.');
          this.calculateRowTotal();
        }
      },
      'tss.userInputDiscount': function (val) {
        if (val != "%") {
          this.tss.userInputDiscount = val.toString().replace(',', '.');
          this.calculateRowTotal();
        }
      },
      'tss.currencyId': function () {
        this.calculateRowTotal();
      },
      'tss.useCreditPrice': function (val) {
        if (val == true) {
          this.tss.rowTotal = this.tss.entity.basePrice.toString().replace(',', '.');
        }
        else {
          this.tss.rowTotal = this.tss.entity.basePriceWithCard.toString().replace(',', '.');
        }
        this.calculateRowTotal();
      },
      serviceKey: function (key) {
        if (key) {
          let entityId = key;
          let entityFromData = this.serviceList.find(s => s.id == entityId);
          if (entityFromData) {
            this.tss.entity = entityFromData;
            this.tss.entityId = entityFromData.id;
            this.tss.rowTotal = (this.tss.useCreditPrice ? entityFromData.basePriceWithCard : entityFromData.basePrice);
            this.tss.service = entityFromData.id + ',' + entityFromData.name
            this.serviceKey = entityFromData.id;
          }

          this.calculateRowTotal(entityId);
        }
      },
      'staffBranchCalendarId': function () {
        if (this.staffId)
          this.getServiceList(this.staffId);
      },
      customerDiscount: {
        handler: function (customerDiscount) {
          if (customerDiscount && customerDiscount.length > 0)
            this.tss.userInputDiscount = this.customerDiscount;
        },
        immediate: true
      }
    },
    mounted: function () {
      if (this.staffId && this.tss.entityType != 'product') {
        this.getServiceList(this.staffId);
      }

      this.serviceKey = this.tss.entityId;
    }
  }
</script>
